import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ManageProduct = () => {
  const navigate = useNavigate(); // Initialize navigate function

  // Dummy Product Data
  const [products, setProducts] = useState([]);

  const getAllProducts = async () => {
    const apiGetProducts = "https://api.rimsjewellery.com/api/products/view";
    const response = await axios.get(apiGetProducts);
    setProducts(response.data);
    console.log(response.data);
  };
  useEffect(() => {
    getAllProducts();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      // setCategories(categories.filter((category) => category.id !== id));
      // alert("Category deleted successfully!");
      const apiDeleteProduct = `https://api.rimsjewellery.com/api/products/delete/${id}`;
      const response = await axios.delete(apiDeleteProduct);
      getAllProducts();
    }
  };

  // Handle Edit Button Click
  // const handleEdit = (id) => {
  //   navigate(`/admin/products/edit/${id}`); // Navigate to the edit page with the product ID
  // };

  return (
    <div className="max-w-6xl mx-auto p-8 mt-10 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">
        Manage Products
      </h2>

      <div className="overflow-x-auto">
        <table className="w-full text-left border border-gray-300">
          <thead>
            <tr className="bg-[#C8A165] text-white">
              <th className="p-3 border border-gray-300">ID</th>
              <th className="p-3 border border-gray-300">Featured Image</th>
              <th className="p-3 border border-gray-300">Product Name</th>
              <th className="p-3 border border-gray-300">Category</th>
              <th className="p-3 border border-gray-300">Price ($)</th>
              <th className="p-3 border border-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id} className="hover:bg-gray-100">
                <td className="p-3 border border-gray-300">{product._id}</td>
                <td className="p-3 border border-gray-300">
                  <img
                    src={`${product.featuredImage}`}
                    alt={product.name}
                    className="w-16 h-16 object-cover rounded-md"
                  />
                </td>
                <td className="p-3 border border-gray-300">{product.name}</td>
                <td className="p-3 border border-gray-300">
                  {product.categoryId.name}
                </td>
                <td className="p-3 border border-gray-300">₹{product.price}</td>
                <td className="p-3 border border-gray-300 flex items-center space-x-4">
                  {/* <FaEdit className="text-blue-500 hover:text-blue-700"
                    onClick={() => handleEdit(product.id)} /> */}

                  <FaTrash
                    className="text-red-500 hover:text-red-700"
                    onClick={() => handleDelete(product._id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageProduct;
