import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

const ManageCategory = () => {
  const navigate = useNavigate(); // Initialize navigate function

  // Dummy data for categories
  const [categories, setCategories] = useState([
    // {
    //   id: 1,
    //   name: "Rings",
    //   description: "Beautiful diamond and gold rings.",
    //   photo: "https://via.placeholder.com/50",
    // },
    // {
    //   id: 2,
    //   name: "Necklaces",
    //   description: "Elegant gold necklaces.",
    //   photo: "https://via.placeholder.com/50",
    // },
    // {
    //   id: 3,
    //   name: "Bracelets",
    //   description: "Stylish bracelets for every occasion.",
    //   photo: "https://via.placeholder.com/50",
    // },
  ]);

  const getAllCategories = async () => {
    const apiGetCategories = "https://api.rimsjewellery.com/api/categories/view";
    const response = await axios.get(apiGetCategories);
    setCategories(response.data);
    console.log(response.data);
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  // Handle delete category
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      // setCategories(categories.filter((category) => category.id !== id));
      // alert("Category deleted successfully!");
      const apiDeleteCategory = `https://api.rimsjewellery.com/api/categories/delete/${id}`;
      const response = await axios.delete(apiDeleteCategory);
      getAllCategories();
    }
  };

  // Handle edit category
  // const handleEdit = async (id) => {
  //   const apiEditCategory = `https://api.rimsjewellery.com/api/categories/update/${id}`
  //     const response = await axios.delete(apiEditCategory);
  //     getAllCategories();

  // };

  return (
    <div className="max-w-5xl mx-auto mt-10 bg-white p-8 shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">
        Manage Categories
      </h2>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-[#C8A165] text-white">
              <th className="p-3 border border-gray-300 text-left">ID</th>
              <th className="p-3 border border-gray-300 text-left">Photo</th>
              <th className="p-3 border border-gray-300 text-left">Name</th>
              <th className="p-3 border border-gray-300 text-left">
                Description
              </th>
              <th className="p-3 border border-gray-300 text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.id} className="hover:bg-gray-100">
                {/* ID */}
                <td className="p-3 border border-gray-300">{category._id}</td>

                {/* Photo */}
                <td className="p-3 border border-gray-300">
                  <img
                    src={category.photo}
                    alt={category.name}
                    className="w-12 h-12 object-cover rounded"
                  />
                </td>

                {/* Name */}
                <td className="p-3 border border-gray-300">{category.name}</td>

                {/* Description */}
                <td className="p-3 border border-gray-300">
                  {category.description}
                </td>

                {/* Actions */}
                <td className="p-3 border border-gray-300 text-center">
                  {/* <button
                    onClick={() => handleEdit(category._id)}
                    className="bg-blue-500 text-white px-3 py-1 rounded-md mr-2 hover:bg-blue-600"
                  >
                    <FaEdit className="inline-block" /> Edit
                  </button> */}
                  <button
                    onClick={() => handleDelete(category._id)}
                    className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                  >
                    <FaTrash className="inline-block" /> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageCategory;
