import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar"; // Sidebar component
import Dashboard from "./components/Dashboard"; // Dashboard component
import AddCategory from "./components/AddCategory"; // Import AddCategory component
import ManageCategory from "./components/ManageCategory"; // Import ManageCategory
import AddProduct from "./components/AddProduct"; // Import the component
import ManageProduct from "./components/ManageProduct"; // Import the component
import EditProduct from "./components/EditProduct"; // Import EditProduct component
import EditCategory from "./components/EditCategory"; // Import the component
import ManageOrders from "./components/ManageOrders"; // Import the component
import NewOrders from './components/NewOrders'; // NewOrders component that displays only pending orders
import UpdateAboutUs from './components/UpdateAboutUs'; // Import the component
import UpdateContactUs from './components/UpdateContactUs'; // Import the component
import RegisteredUsers from './components/RegisteredUsers'; // Import the component
import SearchOrders from './components/SearchOrders'; // Import the component
import Inquiries from "./components/Inquiries";


const App = () => {
  return (
    <Router>
      <div className="flex">
        {/* Sidebar */}
        <Sidebar />

        {/* Main Content Area */}
        <div className="flex-1 ml-64 p-6 bg-gray-100 min-h-screen">
          <Routes>
            {/* Dashboard Route */}
            <Route path="/admin" element={<Dashboard />} />

            {/* Uncomment and add additional routes as needed */}
             <Route path="/admin/categories/add" element={<AddCategory />} />
             <Route path="/admin/categories/manage" element={<ManageCategory />} />
             <Route path="/admin/categories/edit/:id" element={<EditCategory />} />
             <Route path="/admin/products/add" element={<AddProduct />} />
             <Route path="/admin/products/manage" element={<ManageProduct />} />
             <Route path="/admin/products/edit/:id" element={<EditProduct />} />
             <Route path="/admin/orders" element={<ManageOrders />} />
             <Route path="/admin/orders/new" element={<NewOrders />} />
             <Route path="/admin/about" element={<UpdateAboutUs />} />
             <Route path="/admin/contact" element={<UpdateContactUs />} />
             <Route path="/admin/users" element={<RegisteredUsers />} />
             <Route path="/admin/search-order" element={<SearchOrders />} /> 
             <Route path="/admin/inquiries" element={<Inquiries />} /> 
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
